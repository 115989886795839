import React from "react"
import { Link, graphql } from "gatsby"

  import {
    MdPhoneAndroid,
    MdCode,
    MdLaptopMac,
    MdSettingsInputComposite,
    MdArrowBack,
    MdDvr,
    MdPhonelink,
    MdCheck,
    MdArrowForward,
    MdRepeat,
    MdWeb
    
  } from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const DeveloperSoftware = ({data: {image1,laptop }}) => {


    let imgProd = laptop.childImageSharp.fluid.src;

    return (
        <Layout changeHeader={2}>
            <SEO title={`Desarrollo de software`} />

            <section className="hero-wrap hero-wrap-2 mb-2" style={{backgroundImage: `url(${image1.childImageSharp.fluid.src})`}} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                        <Link to ="/#services-section" className="icon ml-5 btn btn-primary" title="Regresar">
                            <MdArrowBack
                                color="#fff"
                                size="2.3em"
                            /> 
                        </Link>
                        
                    </div>
                    <div className="col-md-11 text-center">
                        <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Desarrollo de software </h1>
                        <p className="breadcrumbs"><span className="mr-2">
                            
                                <Link  className="" to="/#services-section">Servicios</Link> > </span> <span>Desarrollo de software</span>
                            </p>
                    </div>
                    </div>
                </div>
            </section>
            <section className="text-justify">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="sidebar-box" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                <h3 className="text-center">Desarrollo de software</h3>

                                <p>&nbsp;&nbsp;&nbsp;Nos especializamos en ofrecer las tendencias tecnológicas a cualquier sector. Nuestra misión es resolver las necesidades de nuestros clientes apoyándonos en las nuevas tecnologías para adaptarlas a su entorno y potenciar su transformación digital. Nuestros esfuerzos van dirigidos a crear soluciones concretas para problemas específicos. Nos encargamos de acercar la transformación digital a las empresas mediante las tendencias tecnológicas.</p>

                                <h3 className="text-center">¿Por qué es importante?</h3>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;En el mundo moderno, con la cantidad de problemas y su complejidad cada vez mayor, el desarrollo de aplicaciones, es una herramienta para obtener soluciones precisas, dándole las tareas repetitivas a la computadora y logrando así la "automatización", de los procesos que todavía requieren la intervención de las personas en tareas repetitivas.</p>

                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Digamos que la importancia del Software para la sociedad, radica en el uso que las personas le den, no se trata solamente de traer juegos nuevos en un dispositivo de 1000 dólares, hablamos de optimizar tareas, incrementar ganancias, aumentar ingresos, optimizar tiempos, hacer la vida más fácil, gracias a que el software hoy en día nos permite hacer infinidad de cosas.</p>                                           
                            </div>
                        </div>

                        <div className="col-md-6 col-12 p-md-7 mt-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                            <img src={imgProd} alt="img project" className="img-fluid rounded mb-1" />
                        </div>

                        <div className="col-md-12 col-12 p-md-4 mt-3" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >

                            


                        </div>

                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section ftco-no-pb" id="contact-section" style={{padding: '2em 0'}}>
              <div className="container-fluid">
                <div className="row justify-content-center mb-1 pb-3">
                  <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                    <h3 className="mb-4">Tipos de desarrollos</h3>
                    <p>Ofrecemos los siguientes tipos de desarrollo de software, aplicando las mejores prácticas y estándares de diseño.</p>
                  </div>
                </div>
                <div className="row d-flex contact-info mb-0 justify-content-center">
                  
                  <div className="col-md-6 col-lg-2 d-flex " style={{}}> 
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px', background: '#1565c0'}} data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdCode color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Desarrollo Web</h6>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#1565c0'}} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdPhoneAndroid color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Desarrollo Móvil</h6>
              
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#1565c0'}} data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdLaptopMac color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Desarrollo Escritorio</h6>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#1565c0' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdSettingsInputComposite color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Desarrollos API</h6>
                    </div>
                  </div>

                </div>
                
              </div>
            </section>
   
            <section className="ftco-section contact-section ftco-no-p mb-3 mt-2" id="contact-section" style={{padding: '2em 0'}}>
              <div className="container-fluid">
                <div className="row justify-content-center mb-1 pb-3">
                  <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                    <h3 className="mb-4">Procesos</h3>
                  </div>
                </div>
                <div className="row d-flex contact-info mb-0 justify-content-center">
                  
                  <div className="col-md-4 col-lg-2 d-flex " style={{}}> 
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px', background: '#388e3c'}} data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdDvr color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Análisis</h6>
                    </div>
                  </div>

                  <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                    <MdArrowForward
                        color="#33a4db"
                        size="3em"
                    /> 
                  </div>

                  <div className="col-md-4 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#f9a825'}} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdWeb color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Diseño</h6>
              
                    </div>
                  </div>

                  <div className="pt-5 d-none d-sm-none d-md-none d-lg-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                    <MdArrowForward
                        color="#33a4db"
                        size="3em"
                    /> 
                  </div> 
                  
                  <div className="col-md-4 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#ef6c00'}} data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdPhonelink color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Desarrollo</h6>
                    </div>
                  </div>

                  <div className="pt-5 d-none d-sm-none  d-md-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                    <MdArrowForward
                        color="#33a4db"
                        size="3em"
                    /> 
                  </div>

                  <div className="col-md-4 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#e65100' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdRepeat color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Pruebas</h6>
                    </div>
                  </div>

                  <div className="pt-5 d-none d-sm-none d-md-none d-lg-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                    <MdArrowForward
                        color="#33a4db"
                        size="3em"
                    /> 
                  </div>

                  <div className="col-md-4 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#039be5' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdCheck color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Implantación</h6>
                    </div>
                  </div>

                </div>
                
              </div>
            </section>
                
        </Layout>
    )
}

export default DeveloperSoftware

export const pageQuery = graphql`
  query FacByDev {

    image1: file(relativePath: { eq: "bannerDev.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    laptop: file(relativePath: { eq: "dev-presen.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
          }
      }
  }

  }
`